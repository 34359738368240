import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { store, switchLogin } from "../Store/Store";
import { Enter } from "./Header";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";

export const AboutAccount = () => {
  const [aboutList, setAboutList] = useState([]);

  const controls = useAnimation();
  const rootRef = useRef();
  const ref = useRef();
  const onScreen = useOnScreen(rootRef);
  const languageStatus = useSelector((state) => state.currentLanguage);

  useEffect(() => {
    languageStatus
      ? setAboutList([
          {
            title: "User Deposit",
            right: "250px",
          },
          { title: "Transaction History/Checks", right: "200px" },
          { title: "Transaction Notifications", right: "150px" },
          { title: "Saving Exchange Pairs", right: "100px" },
          { title: "Cryptocurrency Profit Calculator", right: "50px" },
          { title: "Cryptocurrency Daily Change Dynamics" },
        ])
      : setAboutList([
          {
            title: "Депозит пользователя",
            right: "250px",
          },
          { title: "История сделок/чеки", right: "200px" },
          { title: "Уведомления о сделке", right: "150px" },
          { title: "Сохранение обменных пар", right: "100px" },
          { title: "Калькулятор прибыли криптовалюты", right: "50px" },
          { title: "Динамика суточного изменения криптовалют" },
        ]);
  }, [languageStatus]);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);

  const [enter, setEnter] = useState(false);

  const logStatus = useSelector((state) => state.currentLoginStatus);
  const themeStatus = useSelector((state) => state.currentTheme);

  useEffect(() => {
    setEnter(logStatus);
  }, []);

  function handleClick() {
    document.body.style.overflow = "hidden";
    store.dispatch(switchLogin(true));
    window.scrollTo(0, 0);
  }
  return (
    <motion.div
      className="aboutAccountBox"
      ref={rootRef}
      initial={{ opacity: 0, y: -10 }}
      animate={controls}
    >
      <div className="aboutInfo">
        {" "}
        <div
          className={
            themeStatus == "light"
              ? "title aboutAccountTitle titleDark"
              : "title aboutAccountTitle"
          }
          ref={ref}
        >
          {languageStatus
            ? "PERSONAL ACCOUNT FEATURES"
            : "возможности личного кабинета"}
        </div>
        <div className="aboutBox">
          {" "}
          <div className="aboutLineBox">
            <div className="mainLineBox">
              {" "}
              <div className="mainLine" style={{ height: "560px" }}></div>
              <div className="mainLine" style={{ height: "483px" }}></div>
              <div className="mainLine" style={{ height: "406px" }}></div>
              <div className="mainLine" style={{ height: "330px" }}></div>
              <div className="mainLine" style={{ height: "252px" }}></div>
              <div className="mainLine" style={{ height: "175px" }}></div>
            </div>

            <div className="sideLineBox">
              <div style={{ marginLeft: "-5px" }}></div>
              <div style={{ marginLeft: "-13px" }}></div>
              <div style={{ marginLeft: "-21px" }}></div>
              <div style={{ marginLeft: "-30px" }}></div>
              <div style={{ marginLeft: "-38px" }}></div>
              <div style={{ marginLeft: "-46px" }}></div>
            </div>
          </div>
          <div className="aboutList">
            {aboutList.map((item) => {
              return (
                <div key={item.title} style={{ marginRight: item.right }}>
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>
        <div className="aboutBoxMobile">
          <div className="aboutList">
            {aboutList.map((item) => {
              return (
                <div key={item.title} style={{ marginRight: item.right }}>
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="aboutEnter">
        {localStorage.getItem("accountStatus") == null && (
          <button
            className={
              themeStatus == "light"
                ? "startExchange glowing lightBack"
                : "startExchange glowing"
            }
            onClick={handleClick}
          >
            {languageStatus ? "LOG IN" : "ВХОД"}
          </button>
        )}
      </div>
      {enter && <Enter></Enter>}
    </motion.div>
  );
};
