import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Exchange from "../Api/ExchangeApi/ExchangeApi";

export const History = () => {
  const languageStatus = useSelector((state) => state.currentLanguage);

  const [historyList, setHistoryList] = useState([]);

  async function fetch() {
    const rawList = await Exchange.getHistory();
    let sorted = rawList.sort((a, b) =>
      a.create_date < b.create_date ? 1 : -1
    );

    setHistoryList(
      sorted.map((item) => {
        return { ...item, value: "TON" };
      })
    );
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="historyBox">
      <div>
        {" "}
        <div className="title historyTitle">
          {languageStatus ? "TRANSACTION HISTORY" : "История сделок"}
        </div>{" "}
        <div className="historyList">
          {historyList.map((element) => {
            return (
                <div key={element} className="pairTitles historyPair">
                  <div className="pairDate">
                    {(new Date(element.create_date).getDate() < 10
                      ? "0" + new Date(element.create_date).getDate()
                      : new Date(element.create_date).getDate()) +
                      "." +
                      (Number(new Date(element.create_date).getMonth() + 1) < 10
                        ? "0" +
                          Number(new Date(element.create_date).getMonth() + 1)
                        : Number(
                            new Date(element.create_date).getMonth() + 1
                          )) +
                      "." +
                      new Date(element.create_date).getFullYear()}
                  </div>{" "}
                  <div className="pairDate">
                    {(new Date(element.create_date).getHours() < 10
                      ? "0" + new Date(element.create_date).getHours()
                      : new Date(element.create_date).getHours()) +
                      ":" +
                      (new Date(element.create_date).getMinutes() < 10
                        ? "0" + new Date(element.create_date).getMinutes()
                        : new Date(element.create_date).getMinutes())}
                  </div>
                  <div className="pairValues">
                    <span className="pairFirstSum">{element.amount}</span>
                    <span>{(element.crypt).toUpperCase()}</span>
                    <div className="bulletBox">
                      <div className="line">
                        <div className="bullet"></div>
                      </div>
                    </div>
                    <span>{(element.ex_currency).toUpperCase()}</span>
                  </div>
                  <div className="pairSumBox">
                    <span className="pairFirstSum">{element.sum}</span>
                    <span className="pairSecondSum">{element.sum}</span>
                  </div>
                  <span>
                    Статус:{" "}
                    <span
                      style={{
                        color: "#ffffff",
                      }}
                    >
                      {languageStatus
                        ? element.status === "Created"
                          ? "created"
                          : element.status === "Get"
                          ? "finished"
                          : element.status === "Stop"
                          ? "canceled"
                          : element.status === "Timeout"
                          ? "timeout"
                          : ""
                        : element.status === "Created"
                        ? "создано"
                        : element.status === "Get"
                        ? "завершено"
                        : element.status === "Stop"
                        ? "отменено"
                        : element.status === "Timeout"
                        ? "срок истек"
                        : ""}
                    </span>{" "}
                  </span>
                </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
