import { useEffect, useRef } from "react";
import { ExchangeWindow } from "./ExchangeWindow";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";
import { CryptoAdres } from "./CryptoAdres";
import { useSelector } from "react-redux";
import { cryptoRef, store } from "../Store/Store";
import question from "../icons/fix_best_question.png";

export const Exchanger = ({ openRules }) => {
  const controls = useAnimation();
  const rootRef = useRef();
  const cryptoAdresRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const cryptoCoinRef = useSelector((state) => state.currentCryptoRef);
  const languageStatus = useSelector((state) => state.currentLanguage);

  const executeCryptoScroll = () =>
    cryptoAdresRef.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });

  const handleRulesOpen = () => {
    openRules(true);
  };

  useEffect(() => {
    if (cryptoCoinRef == true) {
      executeCryptoScroll();
      store.dispatch(cryptoRef(false));
    }
  }, []);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 1,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  return (
    <motion.div
      className="mainExchangerBox desktopExchanger"
      ref={rootRef}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
    >
      <div className="title mainExchangeTitle">
        {languageStatus ? "CRYPTOCURRENCY EXCHANGE" : "Обмен криптовалюты"}
      </div>
      <div className="mainExchangeGuid">
        <div className="exchangeInstruction">
          <div className="guidInstruction">
            <div className="guidNumber">1.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Exchange pair" : "Обменная пара"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Select the exchange pair and enter the amount to be exchanged"
                  : `Выберите пару и введите сумму обмена`}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">2.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Wallet address " : "Адрес кошелька"}
              </div>
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Fill in the recipient's cryptocurrency wallet and sender's card details"
                  : "Заполните данные криптокошелька получателя и карты отправителя"}
              </div>
            </div>
          </div>
          <div className="guidInstruction">
            <div className="guidNumber">3.</div>
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {" "}
                {languageStatus ? "Payment" : "Оплата"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Deposit funds to the specified wallet or card\n\nIMPORTANT!\nThe transfer amount must match the amount specified earlier. Otherwise the exchange will not take place."
                  : "Внесите средства на указанный кошелек или карту\n\nВАЖНО!\nСумма перевода должна совпадать с указанной ранее. В противном случае обмен не состоится."}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">4.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Balance check" : "Проверка баланса"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Wait for the status “Funds Received”. \nReceipt will be within a few minutes."
                  : 'Ожидайте статус "Средства получены". \nПоступление будет в течение нескольких минут.'}
              </div>
            </div>
          </div>
        </div>
        <div className="desktopWindow" ref={cryptoAdresRef}>
          <div className="exchangerTerminalTitle">
            {languageStatus ? "CALCULATE THE AMOUNT" : "Рассчитать сумму"}{" "}
            <img
              className="fixRulesQuestion"
              src={question}
              onClick={handleRulesOpen}
              alt=""
            />
          </div>
          <div className="exchangerTerminalContent">
            {" "}
            <ExchangeWindow></ExchangeWindow>
            <CryptoAdres></CryptoAdres>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
