export const QuestionPop = ({ close }) => {
  const onClose = () => {
    close(false);
  };

  const language = JSON.parse(localStorage.getItem("language")) || false;

  return (
    <div className="pop_up">
      {language ? (
        <div className="pop_window questionPopUp">
          <div className="closeBox">
            <button onClick={onClose}>&#215;</button>
          </div>
          <div className="aboutUsText">Cryptocurrency exchange</div>
          <div className="aboutUsText">
            <b>1. Exchange pair</b>
            <br></br>
            Select a pair and enter the exchange amount <br></br>
            <b>⁃ When exchanging cryptocurrency to fiat: </b> <br></br>Enter the
            card number to receive funds. <br></br>
            <b>⁃ When exchanging fiat to cryptocurrency: </b> <br></br>Enter the
            sender's card number and the recipient's cryptocurrency wallet
            address.
          </div>
          <div className="aboutUsText">
            <b>2. Payment and Reporting</b>
            <br></br>⁃ If you have an account, log in
            <br></br>⁃ If you don't have an account, the system will create one
            for you
            <br></br>Wait for the cryptocurrency wallet address or card number
            to appear.
            <br></br>Transfer funds to the specified wallet or card.
            <br></br>
            <b>IMPORTANT!</b>
            <br></br>The transfer amount must match the amount specified
            earlier. Otherwise, the exchange will not take place.
          </div>{" "}
          <div className="aboutUsText">
            <b>3. Account </b> <br></br>You can cancel the transaction, view the
            status and history of exchanges through your account. <br></br>You
            will find a letter with a password for login on the e-mail specified
            during the exchange.
          </div>
          <div className="aboutUsText">
            <b>4. Balance check</b> <br></br>After changing the status of the
            transaction to “Funds received”, wait for crediting to the specified
            wallet or card, depending on the type of exchange. <br></br>
            The crediting will take a few minutes.
          </div>
        </div>
      ) : (
        <div className="pop_window questionPopUp">
          <div className="closeBox">
            <button onClick={onClose}>&#215;</button>
          </div>
          <div className="aboutUsText">Обмен криптовалюты</div>
          <div className="aboutUsText">
            <b>1. Обменная пара</b>
            <br></br>
            Выберите пару и введите сумму обмена <br></br>
            <b>⁃ При обмене криптовалюты на фиат:</b> <br></br>Введите номер
            карты для получения денежных средств. <br></br>
            <b>⁃ При обмене фиат на криптовалюту:</b> <br></br>Введите номер
            карты отправителя и адрес криптокошелька получателя.
          </div>
          <div className="aboutUsText">
            <b>2. Оплата и отчёт</b>
            <br></br>⁃ Если есть аккаунт, авторизуйтесь
            <br></br>⁃ Если аккаунта нет, система создаст его для вас
            <br></br>Дождитесь появления адреса криптокошелька или номера карты.
            <br></br>Переведите средства на указанный кошелек или карту.
            <br></br>
            <b>ВАЖНО!</b>
            <br></br>Сумма перевода должна совпадать с указанной ранее. В
            противном случае обмен не состоится.
          </div>{" "}
          <div className="aboutUsText">
            <b>3. Личный кабинет </b> <br></br>Отменить транзакцию, посмотреть
            статус и историю обменов можно через личный кабинет. <br></br>На эл.
            почте, указанной при обмене, вы найдете письмо с паролем для входа.
          </div>
          <div className="aboutUsText">
            <b>4. Проверка баланса </b> <br></br>После изменения статуса сделки
            на «Средства получены» ожидайте зачисления на указанный кошелек или
            карту, в зависимости от типа обмена. <br></br>Зачисление займет
            несколько минут.
          </div>
        </div>
      )}
    </div>
  );
};
