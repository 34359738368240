import { useEffect, useRef, useState } from "react";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";
import { History } from "./History";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const AccounInfo = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const navigate = useNavigate();
  const languageStatus = useSelector((state) => state.currentLanguage);
  const [cardList,setCardList] = useState(JSON.parse(localStorage.getItem("card_list")) || []) ;

  if (!localStorage.getItem("access")) {
    navigate("/");
  }

  useEffect(() => {
    let result = [];

    for (let str of cardList) {
      if (!result.includes(str)) {
        result.push(str);
      }
    }

    setCardList(result)
  }, [cardList]);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  return (
    <motion.div
      className="accountBox"
      ref={rootRef}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
    >
      <div className="accountInfoBox">
        <div className="accountInfoContent">
          <div className="title accountTitle">
            {languageStatus ? "MY WALLETS" : "Мои кошельки"}
          </div>
          <div className="infoBox accaountInfo">
            <div className="infoTitle">{languageStatus ? "BTC" : "BTC"}:</div>
            <div>12345678</div>
          </div>
          <div className="infoBox accaountInfo">
            <div className="infoTitle">{languageStatus ? "TON" : "TON"}:</div>
            <div>12345678</div>
          </div>
        </div>
        <div className="accountInfoContent">
          <div className="title accountTitle">
            {languageStatus ? "MY CREDIT CARDS" : "Мои карты"}
          </div>
          <div className="cardList">
            {cardList.map((card) => {
              return (
                <div className="infoBox accaountInfo">
                  <div>{card}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <History></History>
    </motion.div>
  );
};
