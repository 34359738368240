import { useEffect, useState, useRef } from "react";
import { useOnScreen } from "./hooks/OnScreen";
import { useAnimation } from "framer-motion";
import {
  dollarStatus,
  store,
  switchAddres,
  switchNumber,
  transitionSum,
} from "../Store/Store";

import rub from "../icons/rub.png";
import eur from "../icons/eur.png";
import usd from "../icons/usd.png";
import btc from "../icons/btc.png";
import eth from "../icons/eth.png";
import usdt from "../icons/usdt.png";
import usdc from "../icons/usdc.png";
import sol from "../icons/sol.png";
import ton from "../icons/ton.png";
import { ClickAwayListener, Tooltip } from "@mui/material";
import question from "../icons/fix_best_question.png";
import { useSelector } from "react-redux";

export const ExchangeWindow = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const [open, setOpen] = useState(false);
  const languageStatus = useSelector((state) => state.currentLanguage);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  const [valueInput, setValueInput] = useState(
    Number(localStorage.getItem("valueInput")) || null
  );
  const [finalPrice, setFinalPrice] = useState();
  const [valueCurrancy, setValueCurrancy] = useState(
    JSON.parse(localStorage.getItem("firstValue")) || {
      title: "USD",
      coin: "usd",
    }
  );

  const [coinCurrancy, setCoinCurrancy] = useState(
    JSON.parse(localStorage.getItem("secondValue")) || {
      title: "BTC",
      coin: "bitcoin",
    }
  );

  const [selectVisible, setSelectVisible] = useState("none");
  const [selectVisibleCoin, setSelectVisibleCoin] = useState("none");
  const [changeStatus, setChangeStatus] = useState(false);
  const fiantList = [
    { title: "USD", src: usd, coin: "usd" },
    { title: "EUR", src: eur, coin: "eur" },
    { title: "RUB", src: rub, coin: "rub" },
    { title: "BTC", src: btc, coin: "bitcoin" },
    { title: "ETH", src: eth, coin: "ethereum" },
    { title: "USDT", src: usdt, coin: "tether" },
    { title: "USDC", src: usdc, coin: "usd-coin" },
    { title: "SOL", src: sol, coin: "solana" },
    { title: "TON", src: ton, coin: "the-open-network" },
  ];
  const cryptoList = [
    { title: "USD", src: usd, coin: "usd" },
    { title: "EUR", src: eur, coin: "eur" },
    { title: "RUB", src: rub, coin: "rub" },
    { title: "BTC", src: btc, coin: "bitcoin" },
    { title: "ETH", src: eth, coin: "ethereum" },
    { title: "USDT", src: usdt, coin: "tether" },
    { title: "USDC", src: usdc, coin: "usd-coin" },
    { title: "SOL", src: sol, coin: "solana" },
    { title: "TON", src: ton, coin: "the-open-network" },
  ];
  const checkCryptoList = ["BTC", "ETH", "USDT", "USDC", "SOL", "TON"];

  const checkFiantList = ["USD", "EUR", "RUB"];

  useEffect(() => {
    if (!changeStatus) {
      localStorage.setItem("transitionCoin", coinCurrancy.title);
      localStorage.setItem("getCoin", valueCurrancy.title);
    }
    if (changeStatus) {
      localStorage.setItem("transitionCoin", valueCurrancy.title);
      localStorage.setItem("getCoin", coinCurrancy.title);
    }
  }, [coinCurrancy, valueCurrancy, changeStatus]);

  // const [fixBest, setFixBest] = useState([
  //   {
  //     label: "Лучший",
  //     class: "bestCurrancy",
  //     tip: "Система автоматически пересчитает Вашу заявку при резких колебаниях курса. Обращаем Ваше внимание, что сумма к получению изменяется как в меньшую, так и в большую для Вас сторону. Исполнение происходит по курсу на момент поступления средств от Вас.",
  //     active: true,
  //   },
  //   {
  //     label: "Фикс",
  //     class: "fixCurrancy",
  //     tip: "Система фиксирует курс при создании заявки на все время отведенное для вашей заявки. В среднем это 60 минут.",
  //     active: false,
  //   },
  // ]);

  const cryptoData = JSON.parse(localStorage.getItem("cryptoData")) || [];

  const handleChange = (e) => {
    const result = e.target.value.replace(/[^\d.]/g, "");
    setValueInput(result);
  };

  if (!changeStatus) {
    localStorage.setItem("transitionCoin", coinCurrancy.title);
  }
  if (changeStatus) {
    localStorage.setItem("transitionCoin", valueCurrancy.title);
  }

  useEffect(() => {
    if (changeStatus && cryptoData) {
      store.dispatch(
        transitionSum(
          valueInput,
          valueInput * cryptoData[coinCurrancy.coin][valueCurrancy.coin]
        )
      );
    }
    if (!changeStatus && cryptoData) {
      store.dispatch(
        transitionSum(
          valueInput,
          valueInput * cryptoData[valueCurrancy.coin][coinCurrancy.coin]
        )
      );
    }
  }, [valueInput, finalPrice, changeStatus]);

  useEffect(() => {
    if (
      !changeStatus &&
      valueInput * cryptoData[coinCurrancy.coin]["USD"] < 100
    ) {
      store.dispatch(dollarStatus(true));
    } else if (
      changeStatus &&
      valueInput * cryptoData[valueCurrancy.coin]["USD"] < 100
    ) {
      store.dispatch(dollarStatus(true));
    } else {
      store.dispatch(dollarStatus(false));
    }
  }, [changeStatus, coinCurrancy, valueCurrancy, valueInput]);

  function handleSelect() {
    setSelectVisibleCoin("none");
    if (selectVisible == "none") {
      setSelectVisible("grid");
    }
    if (selectVisible == "grid") {
      setSelectVisible("none");
    }
  }

  function handleSelectCoin() {
    setSelectVisible("none");
    if (selectVisibleCoin == "none") {
      setSelectVisibleCoin("grid");
    }
    if (selectVisibleCoin == "grid") {
      setSelectVisibleCoin("none");
    }
  }

  window.addEventListener("click", (e) => {
    const target = e.target;
    if (!target.closest(".selectBox") && !target.closest(".exchangeOptions")) {
      setSelectVisible("none");
      setSelectVisibleCoin("none");
    }
  });

  function changeCryptoFiant() {
    if (changeStatus) {
      setChangeStatus(false);
    }
    if (!changeStatus) {
      setChangeStatus(true);
    }
  }

  useEffect(() => {
    if (changeStatus) {
      if (
        checkFiantList.includes(valueCurrancy.title) &&
        checkCryptoList.includes(coinCurrancy.title)
      ) {
        store.dispatch(switchNumber(true));
        store.dispatch(switchAddres(true));
        document.querySelector(".cryptoPayment").style.height = "180px";

        document.querySelector(".desktopWindow").style.height = "660px";
      }
      if (
        checkCryptoList.includes(valueCurrancy.title) &&
        checkFiantList.includes(coinCurrancy.title)
      ) {
        store.dispatch(switchNumber(true));
        store.dispatch(switchAddres(false));
        document.querySelector(".cryptoPayment").style.height = "90px";
        document.querySelector(".desktopWindow").style.height = "550px";
      }
      if (
        checkCryptoList.includes(valueCurrancy.title) &&
        checkCryptoList.includes(coinCurrancy.title)
      ) {
        store.dispatch(switchNumber(false));
        store.dispatch(switchAddres(true));
        document.querySelector(".cryptoPayment").style.height = "90px";
        document.querySelector(".desktopWindow").style.height = "550px";
      }
    }

    if (!changeStatus) {
      if (
        checkFiantList.includes(coinCurrancy.title) &&
        checkCryptoList.includes(valueCurrancy.title)
      ) {
        store.dispatch(switchNumber(true));
        store.dispatch(switchAddres(true));
        document.querySelector(".cryptoPayment").style.height = "180px";

        document.querySelector(".desktopWindow").style.height = "660px";
      }
      if (
        checkCryptoList.includes(coinCurrancy.title) &&
        checkFiantList.includes(valueCurrancy.title)
      ) {
        store.dispatch(switchNumber(true));
        store.dispatch(switchAddres(false));
        document.querySelector(".cryptoPayment").style.height = "90px";
        document.querySelector(".desktopWindow").style.height = "550px";
      }
      if (
        checkCryptoList.includes(coinCurrancy.title) &&
        checkCryptoList.includes(valueCurrancy.title)
      ) {
        store.dispatch(switchNumber(false));
        store.dispatch(switchAddres(true));
        document.querySelector(".cryptoPayment").style.height = "90px";
        document.querySelector(".desktopWindow").style.height = "550px";
      }
    }
  }, [
    valueCurrancy,
    coinCurrancy,
    changeStatus,
    checkFiantList,
    checkCryptoList,
  ]);

  // function fixBestClick(item) {
  //   setFixBest(
  //     fixBest.map((listItem) => {
  //       if (item == listItem) {
  //         setFixBest(listItem.current);
  //         return { ...listItem, active: true };
  //       } else {
  //         return { ...listItem, active: false };
  //       }
  //     })
  //   );
  // }

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (
      checkFiantList.includes(valueCurrancy.title) &&
      checkFiantList.includes(coinCurrancy.title) &&
      changeStatus
    ) {
      setCoinCurrancy({
        title: "BTC",
        coin: "bitcoin",
      });
    }

    if (
      checkFiantList.includes(valueCurrancy.title) &&
      checkFiantList.includes(coinCurrancy.title) &&
      !changeStatus
    ) {
      setValueCurrancy({
        title: "BTC",
        coin: "bitcoin",
      });
    }
  }, [valueCurrancy, coinCurrancy, changeStatus]);

  return (
    <div>
      {" "}
      <div className="exchangerValuesBox" ref={rootRef}>
        <div className="transitionInputBox">
          <span className="exchangeTitle">
            {languageStatus ? "Giving" : "Отдаете"}
          </span>
          <div className="valueBox exchangerValueBox">
            <input
              className="exchangeInput"
              value={valueInput}
              onChange={handleChange}
            ></input>
            <div className="selectBox">
              <button className="exchangeSelect" onClick={handleSelect}>
                {changeStatus ? valueCurrancy.title : coinCurrancy.title}
                <svg className="strelka-bottom-3" viewBox="0 0 5 9">
                  <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"></path>
                </svg>
              </button>{" "}
              <ul
                className="exchangeOptions selectOptions"
                style={{ display: selectVisible }}
              >
                {changeStatus
                  ? fiantList.map((item) => {
                      return (
                        <li
                          key={item.title}
                          onClick={() => {
                            changeStatus
                              ? setValueCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                })
                              : setCoinCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                });

                            setSelectVisible("none");
                            setValueInput("");
                            localStorage.setItem("transitionCoin", item.title);
                          }}
                        >
                          <img className="coinIcon" src={item.src} alt=""></img>
                          {item.title}
                        </li>
                      );
                    })
                  : cryptoList.map((item) => {
                      return (
                        <li
                          key={item.title}
                          onClick={() => {
                            changeStatus
                              ? setValueCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                })
                              : setCoinCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                });
                            setSelectVisible("none");
                            setValueInput("");
                            localStorage.setItem("transitionCoin", item.title);
                          }}
                        >
                          <img className="coinIcon" src={item.src} alt=""></img>
                          {item.title}
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </div>
        <div className="changeFiantCryptoExchanger">
          <div onClick={changeCryptoFiant}>&#8646;</div>
        </div>
        <div className="transitionInputBox">
          <span className="exchangeTitle">
            {" "}
            {languageStatus ? "Getting" : "Получаете"}
          </span>
          <div className="valueBox exchangerValueBox">
            <input
              className="exchangeInput"
              readOnly
              value={
                cryptoData
                  ? Number(
                      valueInput *
                        (changeStatus
                          ? cryptoData[valueCurrancy.coin][coinCurrancy.coin] >
                            0
                            ? cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                            : cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                          : cryptoData[coinCurrancy.coin][valueCurrancy.coin] >
                            0
                          ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                          : cryptoData[coinCurrancy.coin][valueCurrancy.coin]) -
                        valueInput *
                          ((changeStatus
                            ? cryptoData[valueCurrancy.coin][
                                coinCurrancy.coin
                              ] > 0
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                              : cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ] > 0
                            ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ]) *
                            0.03)
                    ).toFixed(2) == 0
                    ? Number(
                        valueInput *
                          (changeStatus
                            ? cryptoData[valueCurrancy.coin][
                                coinCurrancy.coin
                              ] > 0
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                              : cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ] > 0
                            ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ]) -
                          valueInput *
                            ((changeStatus
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ] > 0
                                ? cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                                : cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ] > 0
                              ? cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]) *
                              0.03)
                      ).toFixed(5)
                    : Number(
                        valueInput *
                          (changeStatus
                            ? cryptoData[valueCurrancy.coin][
                                coinCurrancy.coin
                              ] > 0
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                              : cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ] > 0
                            ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ]) -
                          valueInput *
                            ((changeStatus
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ] > 0
                                ? cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                                : cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ] > 0
                              ? cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]) *
                              0.03)
                      ).toFixed(2)
                  : ""
              }
            ></input>
            <div className="selectBox">
              <button className="exchangeSelect" onClick={handleSelectCoin}>
                {changeStatus ? coinCurrancy.title : valueCurrancy.title}
                <svg className="strelka-bottom-3" viewBox="0 0 5 9">
                  <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"></path>
                </svg>
              </button>
              <ul
                className="exchangeOptions selectOptions"
                style={{ display: selectVisibleCoin }}
              >
                {changeStatus
                  ? cryptoList.map((item) => {
                      return (
                        <li
                          key={item.title}
                          onClick={() => {
                            changeStatus
                              ? setCoinCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                })
                              : setValueCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                });
                            setSelectVisibleCoin("none");
                            setValueInput("");
                            localStorage.setItem("getCoin", item.title);
                          }}
                        >
                          <img className="coinIcon" src={item.src} alt=""></img>
                          {item.title}
                        </li>
                      );
                    })
                  : fiantList.map((item) => {
                      return (
                        <li
                          key={item.title}
                          onClick={() => {
                            changeStatus
                              ? setCoinCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                })
                              : setValueCurrancy({
                                  title: item.title,
                                  coin: item.coin,
                                });
                            setSelectVisibleCoin("none");
                            setValueInput("");
                            localStorage.setItem("getCoin", item.title);
                          }}
                        >
                          <img className="coinIcon" src={item.src} alt=""></img>
                          {item.title}
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="exchangerValuesBox">
        <div className="transitionInputBox">
          <div className="valueBox exchangerValueBox">
            <input
              className="exchangeInput"
              readOnly
              value={
                Number(
                  (cryptoData
                    ? changeStatus
                      ? valueInput *
                          cryptoData[valueCurrancy.coin][coinCurrancy.coin] >
                        0
                        ? valueInput *
                          cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                        : valueInput *
                          cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                      : valueInput *
                          cryptoData[coinCurrancy.coin][valueCurrancy.coin] >
                        0
                      ? valueInput *
                        cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                      : valueInput *
                        cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                    : "") * 0.03
                ).toFixed(2) == 0
                  ? Number(
                      (cryptoData
                        ? changeStatus
                          ? valueInput *
                              cryptoData[valueCurrancy.coin][
                                coinCurrancy.coin
                              ] >
                            0
                            ? valueInput *
                              cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                            : valueInput *
                              cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                          : valueInput *
                              cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ] >
                            0
                          ? valueInput *
                            cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                          : valueInput *
                            cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                        : "") * 0.03
                    ).toFixed(5)
                  : Number(
                      (cryptoData
                        ? changeStatus
                          ? valueInput *
                              cryptoData[valueCurrancy.coin][
                                coinCurrancy.coin
                              ] >
                            0
                            ? valueInput *
                              cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                            : valueInput *
                              cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                          : valueInput *
                              cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ] >
                            0
                          ? valueInput *
                            cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                          : valueInput *
                            cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                        : "") * 0.03
                    ).toFixed(2)
              }
            ></input>
            <div className="commisionLabel">
              {languageStatus ? "commission" : "комиссия"}
            </div>
          </div>
        </div>
        {/* <div className="changeFiantCryptoExchanger"></div>
        <div className="transitionInputBox secondComission">
          <div className="valueBox exchangerValueBox">
            <input
              className="exchangeInput"
              value={Number(valueInput) * 0.03 + Number(valueInput)}
            ></input>
            <div className="commisionLabel">комиссия</div>
          </div>
        </div> */}
      </div>
      {/* <div className="chooseCurrencyRateBox">
        {fixBest.map((item) => (
          <Tooltip
            key={item.label}
            title={<div className="fixBestDescription">{item.tip}</div>}
          >
            <div
              className={
                item.active == true
                  ? `${item.class} chosenFixBest`
                  : `${item.class}`
              }
              onClick={() => fixBestClick(item)}
            >
              {item.label}
            </div>
          </Tooltip>
        ))}
      </div> */}
      <div className="reservBox">
        <div className="currentExchangeCurrancyBox">
          <div className="currentExchangeCurrancy">
            {languageStatus ? "Current exchange rate" : "Текущий курс"}:
            <br></br>
            {cryptoData
              ? Number(
                  changeStatus
                    ? cryptoData[valueCurrancy.coin][coinCurrancy.coin] > 0
                      ? cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                      : ""
                    : cryptoData[coinCurrancy.coin][valueCurrancy.coin] > 0
                    ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                    : ""
                ).toFixed(2) == 0
                ? Number(
                    changeStatus
                      ? cryptoData[valueCurrancy.coin][coinCurrancy.coin] > 0
                        ? cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                        : ""
                      : cryptoData[coinCurrancy.coin][valueCurrancy.coin] > 0
                      ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                      : ""
                  ).toFixed(5)
                : Number(
                    changeStatus
                      ? cryptoData[valueCurrancy.coin][coinCurrancy.coin] > 0
                        ? cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                        : ""
                      : cryptoData[coinCurrancy.coin][valueCurrancy.coin] > 0
                      ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                      : ""
                  ).toFixed(2)
              : ""}{" "}
            {changeStatus ? coinCurrancy.title : valueCurrancy.title}
          </div>{" "}
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <div className="fixBestDescription">
                  {languageStatus
                    ? "The system fixes the rate when an order is created for 60 seconds"
                    : "Система фиксирует курс при создании заявки на 60 секунд"}
                </div>
              }
            >
              <img
                className="fixBestQuestion"
                src={question}
                onClick={handleTooltipOpen}
                alt=""
              />
            </Tooltip>
          </ClickAwayListener>
        </div>
        <div className="currentExchangeCurrancy">
          {languageStatus ? "Reserve" : "Резерв"}:<br></br>0.00{" "}
          {changeStatus ? coinCurrancy.title : valueCurrancy.title}
        </div>
      </div>
    </div>
  );
};
