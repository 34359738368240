import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Footer({ openRules }) {
  const navigate = useNavigate();
  const languageStatus = useSelector((state) => state.currentLanguage);

  const executeContactScroll = () => {
    navigate("/");

    setTimeout(() => {
      document.querySelector(".contactWindow").scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }, 800);
  };

  const handleRulesOpen = () => {
    openRules(true);
  };

  return (
    <div className="footer">
      <div className="footerLogo">ORACLE EXCHANGE</div>
      <div className="footerContent">
        <div className="footerButton">
          {languageStatus
            ? "Cryptocurrency profit calculator"
            : "Калькулятор прибыли криптовалюты"}
        </div>
        <div
          className="footerButton"
          onClick={() => window.open("https://t.me/oracle_coins")}
        >
          {" "}
          {languageStatus ? "Our telegram channel" : "Наш телеграм канал"}
        </div>
        <div className="footerButton" onClick={handleRulesOpen}>
          {languageStatus ? "Rules" : "Правила"}
        </div>
        <div className="footerButton">
          {languageStatus ? "Available limits" : "Доступные лимиты"}
        </div>
        <div className="footerButton" onClick={executeContactScroll}>
          {languageStatus ? "Contact us" : "Связаться с нами"}
        </div>
        <div className="footerButton">
          {languageStatus ? "Privacy Policy" : "Политика конфиденциальности"}
        </div>
        <div className="footerButton">
          {languageStatus ? "Offer" : "Оферта"}
        </div>
      </div>
      <span style={{ position: "absolute", bottom: 0, marginTop: "10px" }}>
        ver 0.252
      </span>
    </div>
  );
}

export default Footer;
