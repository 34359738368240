import { useNavigate } from "react-router-dom";
import logo from "../icons/IMG_2494 2.png";
import logoWithout from "../icons/logo_without.png";
import loginLogo from "../icons/icons8-новый-пост-100 1.png";
import passwordLogo from "../icons/icons8-пароль-500 1.png";
import { useEffect, useState } from "react";
import {
  store,
  switchLanguage,
  switchLogin,
  switchTheme,
} from "../Store/Store";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import axios from "axios";
import { ExchangerRegistration } from "./ExchangerRegistration";
import { ContinuePop } from "./ContinuePop";
// import darkIcon from "../icons/dark-light.png";
// import lightIcon from "../icons/light-dark.png";
import telegramLogo from "../icons/telegram.png";
import emailLogo from "../icons/email.png";
import burger from "../icons/menu-bar.png";
import closeMenu from "../icons/close_menu.png";
import { AboutUs } from "./AboutUs";

export function Enter() {
  const [registration, setRegistration] = useState(false);
  const [loginStatus, setLoginStatus] = useState(true);
  const [loading, setLoad] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState();
  const [accountStatus, setAccountStatus] = useState(
    JSON.parse(localStorage.getItem("accountStatus")) || false
  );
  const languageStatus = useSelector((state) => state.currentLanguage);
  const [error, setError] = useState(false);

  const mailTest = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;

  const handleClick = () => {
    document.body.style.overflow = "";
    localStorage.removeItem("accountStatus");
    localStorage.removeItem("access");
    store.dispatch(switchLogin(false));
  };

  function handleLogin() {
    if (!mailTest.test(login)) {
      setError(true);
    }

    if (mailTest.test(login) && password.length > 0) {
      const params = new URLSearchParams();
      params.append("username", login);
      params.append("password", password);

      async function fetchRegistration() {
        try {
          setLoad(true);
          await axios
            .post("https://point1.bot-n.ru/login", params)
            .then((response) => {
              setLoad(false);
              localStorage.setItem("access", response.data.access_token);
            });

          document.body.style.overflow = "";

          window.location.reload();

          if (mailTest.test(login)) {
            localStorage.setItem("email", login);
          }

          setAccountStatus(true);
          localStorage.setItem("accountStatus", accountStatus);
          // if (phoneTest.test(login)) {
          //   localStorage.setItem("phone", login);
          // }

          store.dispatch(switchLogin(false));
        } catch {
          localStorage.removeItem("accauntStatus");
          localStorage.removeItem("email");
          setLoad(false);
          setError(true);
          return;
        }
      }
      fetchRegistration();
    }
  }

  const changeLogin = (e) => {
    setLogin(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="pop_up">
      {loginStatus && (
        <div className="pop_window signWindow">
          <div className="closeBox">
            <button onClick={handleClick}>&#215;</button>
          </div>
          <div className="signInContent">
            <div className="conentPart">
              <div className="loginTitle">Log in</div>
              <div className="loginInputBox">
                {error && (
                  <label style={{ color: "red" }}>
                    {languageStatus
                      ? "Email or password is incorrect"
                      : "Почта или пароль введены неверно"}{" "}
                  </label>
                )}
                <div className="inputBox">
                  <img
                    style={{ height: "30px", width: "53px" }}
                    src={loginLogo}
                    alt=""
                  ></img>
                  <input
                    className="login"
                    placeholder="Email"
                    value={login}
                    onChange={changeLogin}
                  ></input>
                </div>
                <div className="inputBox">
                  <img src={passwordLogo} alt=""></img>
                  <input
                    type="password"
                    className="password"
                    placeholder=". . . ."
                    value={password}
                    onChange={changePassword}
                  ></input>
                </div>
              </div>
            </div>{" "}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  minWidth: "100%",
                }}
              >
                <ReactLoading
                  type="spinningBubbles"
                  color="white"
                  height={"50px"}
                  width={"50px"}
                />
              </div>
            ) : (
              <div className="conentPart">
                <button className="enterButton" onClick={handleLogin}>
                  Login to your account
                </button>
                <div className="forgetBox">
                  <button>Forgot Password?</button>
                  <div className="newPersonBox">
                    <div>New here?</div>{" "}
                    <button
                      className="goSignUp"
                      onClick={() => {
                        setRegistration(true);
                        setLoginStatus(false);
                      }}
                    >
                      Sign Up!
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {registration && <Registration></Registration>}
    </div>
  );
}

export function Registration() {
  const [login, setLogin] = useState("");
  const [loading, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const languageStatus = useSelector((state) => state.currentLanguage);

  const navigate = useNavigate();

  const mailTest = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
  // const phoneTest = /^[\d\+][\d\(\)\ -]{4,14}\d$/;

  function handleClick() {
    document.body.style.overflow = "";
    store.dispatch(switchLogin(false));
  }

  function handleRegistration() {
    localStorage.setItem("email", "");
    localStorage.setItem("phone", "");

    if (!mailTest.test(login)) {
      setError(true);
    }
    if (login.length > 0 && mailTest.test(login)) {
      async function fetchRegistration() {
        try {
          setLoad(true);
          await axios
            .get("https://point1.bot-n.ru/register_account", {
              params: { email: login },
            })
            .then(() => {
              setLoad(false);
            });

          document.body.style.overflow = "";

          alert(
            languageStatus
              ? "A confirmation link and login password have been sent to the specified e-mail address"
              : "На указанную почту отправлена ссылка для подтверждения и пароль для входа"
          );
          navigate("/");

          // if (mailTest.test(login)) {
          //   localStorage.setItem("email", login);
          // }

          // if (phoneTest.test(login)) {
          //   localStorage.setItem("phone", login);
          // }

          store.dispatch(switchLogin(false));
        } catch {
          setLoad(false);
          setError(true);
          return;
        }
      }
      fetchRegistration();
    }
  }

  return (
    <div className="pop_up">
      <div className="pop_window  signUpWindow">
        <div className="closeBox">
          <button onClick={handleClick}>&#215;</button>
        </div>
        <div className="signUpContent">
          <div className="conentPart">
            {" "}
            <div className="loginTitle">Sign Up</div>
          </div>
          <div className="conentPart">
            {error && (
              <label style={{ color: "red" }}>
                {languageStatus
                  ? "Email is incorrect"
                  : "Почта введена неверно"}{" "}
              </label>
            )}
            <div className="signUpInputBox">
              <div className="inputBox">
                <img
                  style={{ height: "30px", width: "53px" }}
                  src={loginLogo}
                  alt=""
                ></img>
                <input
                  className="login"
                  placeholder="Email"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                ></input>
              </div>{" "}
            </div>
          </div>
          <div className="conentPart">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  minWidth: "100%",
                }}
              >
                <ReactLoading
                  type="spinningBubbles"
                  color="white"
                  height={"50px"}
                  width={"50px"}
                />
              </div>
            ) : (
              <button className="enterButton" onClick={handleRegistration}>
                Sign Up
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export const Header = () => {
  const [enter, setEnter] = useState(false);
  const navigate = useNavigate();
  const [themeSwitch, setThemeSwitch] = useState(
    localStorage.getItem("themeSwitch") || "light"
  );
  const [theme, setTheme] = useState("headerBox");
  const logStatus = useSelector((state) => state.currentLoginStatus);
  const profileStatus = useSelector((state) => state.currentProfileSwitch);
  const [accountStatus, setAccountStatus] = useState(
    localStorage.getItem("accountStatus") || false
  );
  const [registration, setRegistration] = useState(false);
  const [continueStatus, setContinueStatus] = useState(false);
  const [menuStatus, setMenuStatus] = useState(false);
  const [aboutPop, setAboutPop] = useState(false);
  const [language, setLanguage] = useState(
    JSON.parse(localStorage.getItem("language")) || false
  );
  store.dispatch(switchLanguage(JSON.parse(localStorage.getItem("language"))));
  const registrationStatus = useSelector(
    (state) => state.currentRegistrationExchange
  );

  useEffect(() => {
    setRegistration(registrationStatus);
  });

  const currentContinue = useSelector((state) => state.currentContinue);

  useEffect(() => {
    setContinueStatus(currentContinue);
  });

  // const currancyRef = useSelector((state) => state.currentCurrancyRef);
  // const pairsRef = useSelector((state) => state.currentPairsRef);

  useEffect(() => {
    setEnter(logStatus);
  });

  function handleClick() {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    store.dispatch(switchLogin(true));
  }

  function goProfile() {
    navigate(`/accaount`);
  }

  function handleExit() {
    setAccountStatus(false);
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }

  const executeCurrancyScroll = () => {
    navigate("/");

    setTimeout(() => {
      document.querySelector(".currancyBox").scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }, 800);
  };

  const executePairsScroll = () => {
    navigate("/");

    setTimeout(() => {
      document.querySelector(".exchangePairesBox").scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }, 800);
  };

  // function lightSwitch() {
  //   if (themeSwitch == "dark") {
  //     setThemeSwitch("light");
  //   }

  //   if (themeSwitch == "light") {
  //     setThemeSwitch("dark");
  //   }
  // }

  function closeAbout(status) {
    setAboutPop(status);
  }

  useEffect(() => {
    if (themeSwitch == "dark") {
      setTheme("headerBoxLight");
      store.dispatch(switchTheme("light"));
      document.querySelector("body").style.backgroundColor = "#fafafa";
    }

    if (themeSwitch == "light") {
      setTheme("headerBox");
      store.dispatch(switchTheme("dark"));
      document.querySelector("body").style.backgroundColor = "#080e1a";
    }
    localStorage.setItem("themeSwitch", themeSwitch);
  }, [themeSwitch]);

  function menuClick() {
    if (menuStatus == true) {
      setMenuStatus(false);
    }
    if (menuStatus == false) {
      setMenuStatus(true);
    }
  }

  function changeLanguage() {
    if (language) {
      setLanguage(false);
      localStorage.setItem("language", JSON.stringify(false));
      store.dispatch(switchLanguage(false));
    } else {
      setLanguage(true);
      localStorage.setItem("language", JSON.stringify(true));
      store.dispatch(switchLanguage(true));
    }
  }

  return (
    <div>
      <div className="contactHeaderBox">
        <div>
          {" "}
          {menuStatus ? (
            <img
              className="burgerIcon burgerIconActive"
              src={closeMenu}
              onClick={menuClick}
              alt=""
            ></img>
          ) : (
            <img
              className="burgerIcon"
              src={burger}
              onClick={menuClick}
              alt=""
            ></img>
          )}
        </div>

        <div>
          {" "}
          <img src={telegramLogo} alt=""></img>
          <a href="https://t.me/TonyPeppperony">Support</a>
          <img src={emailLogo} style={{ opacity: "0.8" }} alt=""></img>
          <a href="mailto:oracle.exchanger@gmail.com">Contact us</a>
        </div>
      </div>{" "}
      <div className={theme}>
        <div
          className={themeSwitch == "dark" ? "logoBox logoBoxDark" : "logoBox"}
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} className="logoImage" alt="" />
          <img className="logoWithout" src={logoWithout} alt="" />
          <label>ORACLE EXCHANGE</label>
        </div>

        <div
          className={
            menuStatus
              ? "headerButtonsBox mobileHeaderButtons"
              : "headerButtonsBox"
          }
        >
          <div className="headerButton" onClick={() => setAboutPop(true)}>
            {language ? "ABOUT US" : "О НАС"}
          </div>
          <div className="headerButton" onClick={executeCurrancyScroll}>
            {language ? "CURRENCY RATES" : "КУРСЫ ВАЛЮТ"}
          </div>
          <div className="headerButton" onClick={executePairsScroll}>
            SPECIAL
          </div>
          <div className="languageBox headerButton">
            <div
              className={language == false ? "" : "activePayment"}
              onClick={changeLanguage}
            >
              EN
            </div>
            <div>/</div>
            <div
              className={language == true ? "" : "activePayment"}
              onClick={changeLanguage}
            >
              RUS
            </div>
          </div>
          {accountStatus ? (
            profileStatus ? (
              <div className="headerButton" onClick={handleExit}>
                {language ? "LOG OUT" : "ВЫХОД"}
              </div>
            ) : (
              <div className="headerButton" onClick={goProfile}>
                {language ? "PROFILE" : "ПРОФИЛЬ"}
              </div>
            )
          ) : (
            <div className="headerButton" onClick={handleClick}>
              {language ? "LOG IN" : "ВХОД"}
            </div>
          )}

          {/* <div className="themeIcon" onClick={lightSwitch}>
            <img src={themeSwitch == "dark" ? darkIcon : lightIcon}></img>
          </div> */}
        </div>

        {enter && <Enter></Enter>}
        {registration && <ExchangerRegistration></ExchangerRegistration>}
        {continueStatus && <ContinuePop></ContinuePop>}
        {aboutPop && <AboutUs close={closeAbout}></AboutUs>}
      </div>
    </div>
  );
};
