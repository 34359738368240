import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { profileSwitch, store } from "../Store/Store";
import Exchange from "../Api/ExchangeApi/ExchangeApi";
import copyImage from "../icons/copy.png";
import { ContinueTransitionPop } from "../Components/ContinueTransitionPop";
import { QuestionPop } from "../Components/QuestionPop";

export const TransitionStatus = () => {
  const [openRules, setOpenRules] = useState(false);
  store.dispatch(profileSwitch(false));
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState();
  // const [addres, setAddres] = useState(
  //   localStorage.getItem("transactionAddres")
  // );
  // const [activaTransition, setActivaTransition] = useState(false);
  const transitionCoin = localStorage.getItem("transitionCoin");
  const getCoin = localStorage.getItem("getCoin");
  const [transaction, setTransaction] = useState({});
  const [continuePop, setContinuePop] = useState(false);

  const sumStatus = useSelector((state) => state.currentTransitionSum);
  const languageStatus = useSelector((state) => state.currentLanguage);

  store.dispatch(profileSwitch(false));

  const themeStatus = useSelector((state) => state.currentTheme);

  async function fetch() {
    if (
      transitionCoin === "BTC" &&
      (getCoin === "USD" || getCoin === "EUR" || getCoin === "RUB")
    ) {
      console.log("BTC");

      setTransaction(
        await Exchange.fetchBTCtoFIAT(sumStatus.sum, setTransaction)
      );
    }

    if (
      transitionCoin === "TON" &&
      (getCoin === "USD" || getCoin === "EUR" || getCoin === "RUB")
    ) {
      await Exchange.checkFetchTONtoFIAT(setContinuePop);

      await Exchange.fetchTONtoFIAT(sumStatus.sum, setTransaction);
    }
  }

  function copyAddress(address) {
    navigator.clipboard.writeText(address);
  }

  useEffect(() => console.log(transaction));

  useEffect(() => {
    setLoad(true);
    fetch();
    setLoad(false);
  }, []);

  useEffect(() => {
    if (transaction.status === "pending") {
      setStatus(languageStatus ? "Waiting for address" : "Ожидание адреса");
    }

    if (transaction.status === "transaction_waiting") {
      setStatus(
        languageStatus ? "Waiting for transaction" : "Ожидание транзакции"
      );
    }

    if (transaction.status === "done") {
      setStatus(languageStatus ? "Done" : "Готово");
    }

    if (transaction.status === "Created") {
      setStatus(languageStatus ? "Deal has been created" : "Сделка создана");
    }

    if (transaction.status === "Get") {
      setStatus(languageStatus ? "Funds received" : "Средства получены");
    }

    if (transaction.status === "Stop") {
      setStatus(languageStatus ? "Deal was canceled" : "Сделка отменена");
    }
  }, [languageStatus, transaction]);

  return (
    <div>
      <div className={themeStatus == "dark" ? "mainBox" : "mainBoxLight"}>
        <div className="light x1"></div>
        <div className="light x3"></div>
        <div className="light x5"></div>
        <div className="light x7"></div>
        <div className="light x9"></div>
        <Header></Header>
        <div className="exchangeInstruction">
          <div className="guidInstruction">
            <div className="guidNumber">1.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Exchange pair" : "Обменная пара"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Select the exchange pair and enter the amount to be exchanged"
                  : `Выберите пару и введите сумму обмена`}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">2.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Wallet address " : "Адрес кошелька"}
              </div>
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Fill in the recipient's cryptocurrency wallet and sender's card details"
                  : "Заполните данные криптокошелька получателя и карты отправителя"}
              </div>
            </div>
          </div>
          <div className="guidInstruction">
            <div className="guidNumber">3.</div>
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {" "}
                {languageStatus ? "Payment" : "Оплата"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Deposit funds to the specified wallet or card\n\nIMPORTANT!\nThe transfer amount must match the amount specified earlier. Otherwise the exchange will not take place."
                  : "Внесите средства на указанный кошелек или карту\n\nВАЖНО!\nСумма перевода должна совпадать с указанной ранее. В противном случае обмен не состоится."}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">4.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Balance check" : "Проверка баланса"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Wait for the status “Funds Received”. \nReceipt will be within a few minutes."
                  : 'Ожидайте статус "Средства получены". \nПоступление будет в течение нескольких минут.'}
              </div>
            </div>
          </div>
        </div>
        <div className="transitionStatusBox">
          <div className="transitionTitle">
            {languageStatus
              ? "To complete the transaction, send the funds to this address:"
              : "Для осуществления операции отправьте средства на этот адрес:"}
          </div>
          <div className="transitionAddres">
            {load ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  minWidth: "100%",
                }}
              >
                <ReactLoading
                  type="spinningBubbles"
                  color="white"
                  height={"50px"}
                  width={"50px"}
                />
              </div>
            ) : (
              <div>
                <div className="transitionAddressText">
                  {transaction.addres == null
                    ? languageStatus
                      ? "Waiting for wallet number"
                      : "Ожидание номера кошелька"
                    : transaction.addres}
                </div>
                {transaction.addres == null ? null : (
                  <button
                    className="copyImage"
                    onClick={() => {
                      copyAddress(transaction.addres);
                    }}
                  >
                    <img alt="" src={copyImage}></img>
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="transitionAmount">
            {languageStatus
              ? "You're going to transfer: "
              : "Вы собираетесь перевести: "}
            <div style={{ color: "#ffffff" }}>
              {" "}
              {localStorage.getItem("coinAmount")}{" "}
              {localStorage.getItem("transitionCoin")}
            </div>
          </div>
          <div className="transitionStatus">
            {" "}
            {languageStatus ? "Transaction Status: " : "Статус транзакции: "}
            <div style={{ color: "#ffffff" }}>{status}</div>
          </div>
        </div>
      </div>{" "}
      {continuePop && (
        <ContinueTransitionPop
          sumStatus={sumStatus.sum}
          setTransaction={setTransaction}
          callback={(e) => setContinuePop(e)}
        />
      )}
      <Footer openRules={(e) => setOpenRules(e)}></Footer>
      {openRules && <QuestionPop close={(e) => setOpenRules(e)} />}
    </div>
  );
};
