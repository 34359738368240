import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import rub from "../icons/rub.png";
import eur from "../icons/eur.png";
import usd from "../icons/usd.png";
import btc from "../icons/btc.png";
import eth from "../icons/eth.png";
import usdt from "../icons/usdt.png";
import usdc from "../icons/usdc.png";
import sol from "../icons/sol.png";
import ton from "../icons/ton.png";
import { useSelector } from "react-redux";
import { ClickAwayListener, Tooltip } from "@mui/material";
import question from "../icons/fix_best_question.png";

export const ExchangerPreview = ({ openRules }) => {
  const navigate = useNavigate();
  const [valueInput, setValueInput] = useState("");
  // const [finalPrice, setFinalPrice] = useState(0);
  const [valueCurrancy, setvalueCurrancy] = useState({
    title: "USD",
    coin: "usd",
  });
  const [coinCurrancy, setcoinCurrancy] = useState({
    title: "BTC",
    coin: "bitcoin",
  });
  const [selectVisible, setSelectVisible] = useState("none");
  const [selectVisibleCoin, setSelectVisibleCoin] = useState("none");
  const [changeStatus, setChangeStatus] = useState(false);
  const fiantList = [
    { title: "USD", src: usd, coin: "usd" },
    { title: "EUR", src: eur, coin: "eur" },
    { title: "RUB", src: rub, coin: "rub" },
    { title: "BTC", src: btc, coin: "bitcoin" },
    { title: "ETH", src: eth, coin: "ethereum" },
    { title: "USDT", src: usdt, coin: "tether" },
    { title: "USDC", src: usdc, coin: "usd-coin" },
    { title: "SOL", src: sol, coin: "solana" },
    { title: "TON", src: ton, coin: "the-open-network" },
  ];
  const cryptoList = [
    { title: "USD", src: usd, coin: "usd" },
    { title: "EUR", src: eur, coin: "eur" },
    { title: "RUB", src: rub, coin: "rub" },
    { title: "BTC", src: btc, coin: "bitcoin" },
    { title: "ETH", src: eth, coin: "ethereum" },
    { title: "USDT", src: usdt, coin: "tether" },
    { title: "USDC", src: usdc, coin: "usd-coin" },
    { title: "SOL", src: sol, coin: "solana" },
    { title: "TON", src: ton, coin: "the-open-network" },
  ];

  const checkFiantList = ["USD", "EUR", "RUB"];

  // const [fixBest, setFixBest] = useState([
  //   {
  //     label: "Лучший",
  //     class: "bestCurrancy",
  //     tip: "Система автоматически пересчитает Вашу заявку при резких колебаниях курса. Обращаем Ваше внимание, что сумма к получению изменяется как в меньшую, так и в большую для Вас сторону. Исполнение происходит по курсу на момент поступления средств от Вас.",
  //     active: true,
  //   },
  //   {
  //     label: "Фикс",
  //     class: "fixCurrancy",
  //     tip: "Система фиксирует курс при создании заявки на все время отведенное для вашей заявки. В среднем это 60 минут.",
  //     active: false,
  //   },
  // ]);

  const [cryptoData, setCryptoData] = useState();
  const [currentCurrancy, setCurrentCurrancy] = useState();
  const [open, setOpen] = useState(false);

  const languageStatus = useSelector((state) => state.currentLanguage);
  const themeStatus = useSelector((state) => state.currentTheme);

  const handleChange = (e) => {
    const result = e.target.value.replace(/[^\d.]/g, "");
    setValueInput(result);
  };

  useEffect(() => {
    async function getData() {
      await axios
        .get("https://point1.bot-n.ru/exchange_coin")
        .then((response) => {
          let data = response.data;

          let newData = {};

          for (let coin in data) {
            let currancyData = {};
            for (let currancy in data[coin].now_day) {
              currancyData[currancy] = data[coin].now_day[currancy];
            }
            newData[coin] = currancyData;
          }

          let newCrypto = {};
          let fiatUSD = {};
          let fiatRUB = {};
          let fiatEUR = {};
          let finalCryptoData = {};

          for (let coin in newData) {
            let cryptoDifference = {};
            let coinUSD = newData[coin].usd;
            let coinFiat = newData[coin];

            for (let item in newData) {
              cryptoDifference[item] = 1 / (newData[item].usd / coinUSD);
              fiatUSD[item] = 1 / newData[item].usd;
              fiatRUB[item] = 1 / newData[item].rub;
              fiatEUR[item] = 1 / newData[item].eur;
              newCrypto[coin] = Object.assign(coinFiat, cryptoDifference);
            }
          }

          let finalUSD = {};
          finalUSD["usd"] = fiatUSD;

          let finalRUB = {};
          finalRUB["rub"] = fiatRUB;

          let finalEUR = {};
          finalEUR["eur"] = fiatEUR;

          finalCryptoData = Object.assign(
            newCrypto,
            finalUSD,
            finalRUB,
            finalEUR
          );

          setCryptoData(finalCryptoData);
          localStorage.setItem("cryptoData", JSON.stringify(finalCryptoData));
        });
    }
    getData();
  }, []);

  function handleSelect() {
    setSelectVisibleCoin("none");
    if (selectVisible == "none") {
      setSelectVisible("grid");
    }
    if (selectVisible == "grid") {
      setSelectVisible("none");
    }
  }

  function handleSelectCoin() {
    setSelectVisible("none");
    if (selectVisibleCoin == "none") {
      setSelectVisibleCoin("grid");
    }
    if (selectVisibleCoin == "grid") {
      setSelectVisibleCoin("none");
    }
  }

  window.addEventListener("click", (e) => {
    const target = e.target;
    if (!target.closest(".selectBox") && !target.closest(".exchangeOptions")) {
      setSelectVisible("none");
      setSelectVisibleCoin("none");
    }
  });

  function changeCryptoFiant() {
    if (changeStatus) {
      setChangeStatus(false);
    }
    if (!changeStatus) {
      setChangeStatus(true);
    }
  }

  function goExchange(e) {
    if (
      !changeStatus &&
      valueInput * cryptoData[coinCurrancy.coin]["usd"] < 100
    ) {
      alert("Минимальная сумма входа в сделку 100$ и эквивалент");
    } else if (
      changeStatus &&
      valueInput * cryptoData[valueCurrancy.coin]["usd"] < 100
    ) {
      alert("Минимальная сумма входа в сделку 100$ и эквивалент");
    } else {
      window.scrollTo(0, 150);
      navigate("/exchanger");
      localStorage.setItem("valueInput", valueInput);
      localStorage.setItem("currentCurrancy", currentCurrancy);
      if (changeStatus) {
        localStorage.setItem("firstValue", JSON.stringify(coinCurrancy));
      }
      if (changeStatus) {
        localStorage.setItem("secondValue", JSON.stringify(valueCurrancy));
      }

      if (!changeStatus) {
        localStorage.setItem("firstValue", JSON.stringify(valueCurrancy));
      }
      if (!changeStatus) {
        localStorage.setItem("secondValue", JSON.stringify(coinCurrancy));
      }
    }
  }

  // function fixBestClick(item) {
  //   setFixBest(
  //     fixBest.map((listItem) => {
  //       if (item == listItem) {
  //         setFixBest(listItem.current);
  //         return { ...listItem, active: true };
  //       } else {
  //         return { ...listItem, active: false };
  //       }
  //     })
  //   );
  // }

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleRulesOpen = () => {
    openRules(true);
  };

  useEffect(() => {
    if (
      checkFiantList.includes(valueCurrancy.title) &&
      checkFiantList.includes(coinCurrancy.title) &&
      changeStatus
    ) {
      setcoinCurrancy({ title: "BTC", coin: "bitcoin" });
    }

    if (
      checkFiantList.includes(valueCurrancy.title) &&
      checkFiantList.includes(coinCurrancy.title) &&
      !changeStatus
    ) {
      setvalueCurrancy({ title: "BTC", coin: "bitcoin" });
    }
  }, [valueCurrancy, coinCurrancy, changeStatus]);

  return (
    <>
      {" "}
      <div
        className={
          themeStatus == "dark"
            ? "desktopWindow"
            : "desktopWindow exchangerBoxLight"
        }
      >
        <div className="exchangeButtonsBox">
          {languageStatus ? (
            <div className="exchangerTerminalTitle">Currency exchange</div>
          ) : (
            <div className="exchangerTerminalTitle">Обмен валюты</div>
          )}

          <img
            className="fixRulesQuestion"
            src={question}
            onClick={handleRulesOpen}
            alt=""
          />
        </div>
        <div className="exchangerValuesBox">
          <div className="transitionInputBox">
            <span className="exchangeTitle">
              {languageStatus ? "Giving" : "Отдаете"}
            </span>
            <div className="valueBox exchangerValueBox">
              <input
                className="exchangeInput"
                value={valueInput}
                onChange={handleChange}
              ></input>
              <div className="selectBox">
                <button className="exchangeSelect" onClick={handleSelect}>
                  {changeStatus ? valueCurrancy.title : coinCurrancy.title}
                  <svg className="strelka-bottom-3" viewBox="0 0 5 9">
                    <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"></path>
                  </svg>
                </button>{" "}
                <ul
                  className="exchangeOptions selectOptions"
                  style={{ display: selectVisible }}
                >
                  {changeStatus
                    ? fiantList.map((item) => {
                        return (
                          <li
                            key={item.title}
                            onClick={() => {
                              changeStatus
                                ? setvalueCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  })
                                : setcoinCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  });

                              setSelectVisible("none");
                              setValueInput("");
                              localStorage.setItem(
                                "transitionCoin",
                                item.title
                              );
                            }}
                          >
                            <img
                              className="coinIcon"
                              src={item.src}
                              alt=""
                            ></img>
                            {item.title}
                          </li>
                        );
                      })
                    : cryptoList.map((item) => {
                        return (
                          <li
                            key={item.title}
                            onClick={() => {
                              changeStatus
                                ? setvalueCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  })
                                : setcoinCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  });

                              setSelectVisible("none");
                              setValueInput("");
                              localStorage.setItem(
                                "transitionCoin",
                                item.title
                              );
                            }}
                          >
                            <img
                              className="coinIcon"
                              src={item.src}
                              alt=""
                            ></img>
                            {item.title}
                          </li>
                        );
                      })}
                </ul>
              </div>
            </div>
          </div>
          <div className="changeFiantCryptoExchanger">
            <div onClick={changeCryptoFiant}>&#8646;</div>
          </div>
          <div className="transitionInputBox">
            <span className="exchangeTitle">
              {languageStatus ? "Getting" : "Получаете"}
            </span>
            <div className="valueBox exchangerValueBox">
              <input
                className="exchangeInput"
                readOnly
                value={
                  cryptoData
                    ? Number(
                        valueInput *
                          (changeStatus
                            ? cryptoData[valueCurrancy.coin][
                                coinCurrancy.coin
                              ] > 0
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                              : cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ] > 0
                            ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                            : cryptoData[coinCurrancy.coin][
                                valueCurrancy.coin
                              ]) -
                          valueInput *
                            ((changeStatus
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ] > 0
                                ? cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                                : cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ] > 0
                              ? cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]) *
                              0.03)
                      ).toFixed(2) == 0
                      ? Number(
                          valueInput *
                            (changeStatus
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ] > 0
                                ? cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                                : cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ] > 0
                              ? cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]) -
                            valueInput *
                              ((changeStatus
                                ? cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ] > 0
                                  ? cryptoData[valueCurrancy.coin][
                                      coinCurrancy.coin
                                    ]
                                  : cryptoData[valueCurrancy.coin][
                                      coinCurrancy.coin
                                    ]
                                : cryptoData[coinCurrancy.coin][
                                    valueCurrancy.coin
                                  ] > 0
                                ? cryptoData[coinCurrancy.coin][
                                    valueCurrancy.coin
                                  ]
                                : cryptoData[coinCurrancy.coin][
                                    valueCurrancy.coin
                                  ]) *
                                0.03)
                        ).toFixed(5)
                      : Number(
                          valueInput *
                            (changeStatus
                              ? cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ] > 0
                                ? cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                                : cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ] > 0
                              ? cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]
                              : cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ]) -
                            valueInput *
                              ((changeStatus
                                ? cryptoData[valueCurrancy.coin][
                                    coinCurrancy.coin
                                  ] > 0
                                  ? cryptoData[valueCurrancy.coin][
                                      coinCurrancy.coin
                                    ]
                                  : cryptoData[valueCurrancy.coin][
                                      coinCurrancy.coin
                                    ]
                                : cryptoData[coinCurrancy.coin][
                                    valueCurrancy.coin
                                  ] > 0
                                ? cryptoData[coinCurrancy.coin][
                                    valueCurrancy.coin
                                  ]
                                : cryptoData[coinCurrancy.coin][
                                    valueCurrancy.coin
                                  ]) *
                                0.03)
                        ).toFixed(2)
                    : ""
                }
              ></input>
              <div className="selectBox">
                <button className="exchangeSelect" onClick={handleSelectCoin}>
                  {changeStatus ? coinCurrancy.title : valueCurrancy.title}
                  <svg className="strelka-bottom-3" viewBox="0 0 5 9">
                    <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"></path>
                  </svg>
                </button>
                <ul
                  className="exchangeOptions selectOptions"
                  style={{ display: selectVisibleCoin }}
                >
                  {changeStatus
                    ? cryptoList.map((item) => {
                        return (
                          <li
                            key={item.title}
                            onClick={() => {
                              changeStatus
                                ? setcoinCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  })
                                : setvalueCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  });

                              setSelectVisibleCoin("none");
                              setValueInput("");
                            }}
                          >
                            <img
                              className="coinIcon"
                              src={item.src}
                              alt=""
                            ></img>
                            {item.title}
                          </li>
                        );
                      })
                    : fiantList.map((item) => {
                        return (
                          <li
                            key={item.title}
                            onClick={() => {
                              changeStatus
                                ? setcoinCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  })
                                : setvalueCurrancy({
                                    title: item.title,
                                    coin: item.coin,
                                  });

                              setSelectVisibleCoin("none");
                              setValueInput("");
                            }}
                          >
                            <img
                              className="coinIcon"
                              src={item.src}
                              alt=""
                            ></img>
                            {item.title}
                          </li>
                        );
                      })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="exchangerValuesBox">
          <div className="transitionInputBox">
            <div className="valueBox exchangerValueBox">
              <input
                className="exchangeInput"
                readOnly
                value={
                  Number(
                    (cryptoData
                      ? changeStatus
                        ? valueInput *
                            cryptoData[valueCurrancy.coin][coinCurrancy.coin] >
                          0
                          ? valueInput *
                            cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                          : valueInput *
                            cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                        : valueInput *
                            cryptoData[coinCurrancy.coin][valueCurrancy.coin] >
                          0
                        ? valueInput *
                          cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                        : valueInput *
                          cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                      : "") * 0.03
                  ).toFixed(2) == 0
                    ? Number(
                        (cryptoData
                          ? changeStatus
                            ? valueInput *
                                cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ] >
                              0
                              ? valueInput *
                                cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                              : valueInput *
                                cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                            : valueInput *
                                cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ] >
                              0
                            ? valueInput *
                              cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                            : valueInput *
                              cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                          : "") * 0.03
                      ).toFixed(5)
                    : Number(
                        (cryptoData
                          ? changeStatus
                            ? valueInput *
                                cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ] >
                              0
                              ? valueInput *
                                cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                              : valueInput *
                                cryptoData[valueCurrancy.coin][
                                  coinCurrancy.coin
                                ]
                            : valueInput *
                                cryptoData[coinCurrancy.coin][
                                  valueCurrancy.coin
                                ] >
                              0
                            ? valueInput *
                              cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                            : valueInput *
                              cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                          : "") * 0.03
                      ).toFixed(2)
                }
              ></input>
              <div className="commisionLabel">
                {languageStatus ? "commission" : "комиссия"}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="chooseCurrencyRateBox">
        {fixBest.map((item) => (
          <Tooltip
            key={item.label}
            title={<div className="fixBestDescription">{item.tip}</div>}
          >
            <div
              className={
                item.active == true
                  ? `${item.class} chosenFixBest`
                  : `${item.class}`
              }
              onClick={() => fixBestClick(item)}
            >
              {item.label}
            </div>
          </Tooltip>
        ))}
     
      </div> */}
        <div className="reservBox">
          <div className="currentExchangeCurrancyBox">
            <div className="currentExchangeCurrancy">
              {languageStatus ? "Current exchange rate: " : "Текущий курс: "}
              <br></br>
              {cryptoData
                ? Number(
                    changeStatus
                      ? cryptoData[valueCurrancy.coin][coinCurrancy.coin] > 0
                        ? cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                        : ""
                      : cryptoData[coinCurrancy.coin][valueCurrancy.coin] > 0
                      ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                      : ""
                  ).toFixed(2) == 0
                  ? Number(
                      changeStatus
                        ? cryptoData[valueCurrancy.coin][coinCurrancy.coin] > 0
                          ? cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                          : ""
                        : cryptoData[coinCurrancy.coin][valueCurrancy.coin] > 0
                        ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                        : ""
                    ).toFixed(5)
                  : Number(
                      changeStatus
                        ? cryptoData[valueCurrancy.coin][coinCurrancy.coin] > 0
                          ? cryptoData[valueCurrancy.coin][coinCurrancy.coin]
                          : ""
                        : cryptoData[coinCurrancy.coin][valueCurrancy.coin] > 0
                        ? cryptoData[coinCurrancy.coin][valueCurrancy.coin]
                        : ""
                    ).toFixed(2)
                : ""}{" "}
              {changeStatus ? coinCurrancy.title : valueCurrancy.title}
            </div>{" "}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <div className="fixBestDescription">
                    {languageStatus
                      ? "The system fixes the rate when an order is created for 60 seconds"
                      : "Система фиксирует курс при создании заявки на 60 секунд"}
                  </div>
                }
              >
                <img
                  className="fixBestQuestion"
                  src={question}
                  onClick={handleTooltipOpen}
                  alt=""
                />
              </Tooltip>
            </ClickAwayListener>
          </div>{" "}
          <div className="currentExchangeCurrancy">
            {languageStatus ? "Reserve" : "Резерв"}:<br></br> 0.00
            {changeStatus ? coinCurrancy.title : valueCurrancy.title}
          </div>
        </div>

        <div className="startBox">
          <button
            className={
              themeStatus == "light"
                ? "startExchange glowing lightBack"
                : "startExchange glowing"
            }
            onClick={goExchange}
          >
            {languageStatus ? "EXCHANGE" : "ОБМЕНЯТЬ"}
          </button>
        </div>
      </div>
    </>
  );
};
