export const AboutUs = ({ close }) => {
  function onClose() {
    close(false);
  }

  const language = JSON.parse(localStorage.getItem("language")) || false;

  return (
    <div className="pop_up">
      {language ? (
        <div className="pop_window aboutUsPopUp">
          <div className="closeBox">
            <button onClick={onClose}>&#215;</button>
          </div>
          <div className="aboutUsText">Welcome to our crypto exchange! </div>
          <div className="aboutUsText">
            Our team's top priority is the safety of our clients and quality
            service. We use a multi-level system of protection to ensure maximum
            security of your funds and data.<br></br> Our team guarantees you
            anonymity and confidentiality in every transaction.
          </div>
          <div className="aboutUsText">
            We ensure the fastest possible exchange, thanks to our
            infrastructure and an automated application process.
          </div>{" "}
          <div className="aboutUsText">
            Our experienced support staff is always ready to help you with your
            questions. any questions you may have. We value each client and aim
            for long-term and mutually beneficial cooperation.
          </div>{" "}
          <div className="aboutUsText">
            Join us for a comfortable and secure exchange of cryptocurrency.
          </div>{" "}
          <div className="aboutUsText">Regards, Oracle Exchange team.</div>
        </div>
      ) : (
        <div className="pop_window aboutUsPopUp">
          <div className="closeBox">
            <button onClick={onClose}>&#215;</button>
          </div>
          <div className="aboutUsText">
            Добро пожаловать в наш криптообменник!{" "}
          </div>
          <div className="aboutUsText">
            Главный приоритет нашей команды - это безопасность наших клиентов и
            качественный сервис. Мы используем многоуровневую систему защиты,
            чтобы обеспечить максимальную надежность Ваших средств и данных.{" "}
            <br></br>Наша команда гарантирует Вам анонимность и
            конфиденциальность при каждой сделке.
          </div>
          <div className="aboutUsText">
            Мы обеспечиваем максимально быстрый обмен, благодаря нашей
            инфраструктуре и автоматизированному процессу обработки заявок.
          </div>{" "}
          <div className="aboutUsText">
            Наши опытные специалисты поддержки всегда готовы помочь Вам в
            возникших вопросах. Мы ценим каждого клиента и нацелены на
            долгосрочное и взаимовыгодное сотрудничество.
          </div>{" "}
          <div className="aboutUsText">
            Присоединяйтесь к нам для комфортного и безопасного обмена
            криптовалюты.
          </div>{" "}
          <div className="aboutUsText">
            С уважением, команда Oracle Exchange.
          </div>
        </div>
      )}
    </div>
  );
};
