import { useEffect, useRef } from "react";
import { ExchangerPreview } from "./ExchangerPreview";
import { motion, useAnimation } from "framer-motion";
import { useOnScreen } from "./hooks/OnScreen";
import { useSelector } from "react-redux";

export const ExchangeHome = ({openRules}) => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);

  const themeStatus = useSelector((state) => state.currentTheme);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);

  return (
    <motion.div
      className="exchangeHomeBox"
      ref={rootRef}
      initial={{ opacity: 0, y: -10 }}
      animate={controls}
    >
      <div className="exchangeHomeTitleBox">
        <div
          className={
            themeStatus == 'dark'
              ? "title exchangeHomeLabel"
              : "title exchangeHomeLabel titleDark"
          }
        >
          INVEST IN YOUR FUTURE
        </div>
        {/* <div className={themeStatus == 'dark'
              ? "exchangeHomeButtonsBox"
              : "exchangeHomeButtonsBox titleDark"
          }>
          <div style={{ width: "250px", marginLeft: "0" }} > CHANGE MONEY</div>
          <div style={{ width: "250px", margin: "0 auto" }}>CHANGE CRYPTO</div>
          <div style={{ width: "250px", marginLeft: "150px" }}>
            CHANGE EVERYTHING
          </div>
        </div> */}
      </div>
      <ExchangerPreview openRules={openRules}></ExchangerPreview>
    </motion.div>
  );
};
